const showText = function(textContainer, moreTextToggle, lessTextToggle) {
  return e => {
    e.preventDefault();
    textContainer.classList.add('more-text--shown');
    moreTextToggle.classList.remove('more-text--shown');
    lessTextToggle.classList.add('more-text--shown');
  };
};

const hideText = function(textContainer, moreTextToggle, lessTextToggle) {
  return e => {
    e.preventDefault();
    textContainer.classList.remove('more-text--shown');
    moreTextToggle.classList.add('more-text--shown');
    lessTextToggle.classList.remove('more-text--shown');
  };
};

const initToggler = function(container) {
  const textContainer = container.getElementsByClassName('more-text__text')[0];
  const lessTextToggle = container.getElementsByClassName('more-text__less')[0];
  const moreTextToggle = container.getElementsByClassName('more-text__more')[0];

  if(textContainer !== undefined &&
     lessTextToggle !== undefined &&
     moreTextToggle !== undefined) {
    lessTextToggle.addEventListener('click', hideText(textContainer, moreTextToggle, lessTextToggle));
    moreTextToggle.addEventListener('click', showText(textContainer, moreTextToggle, lessTextToggle));
  };
};

const init = function() {
  const containers = document.querySelectorAll('[data-toggle-more]');
  containers.forEach(initToggler);
};

export default init;
