let searchFilter;

const showFilters = function() {
  searchFilter.classList.add("search-filter--shown");
};

const hideFilters = function() {
  searchFilter.classList.remove("search-filter--shown");
};

const init = function() {
  searchFilter = document.getElementById("search-filter");

  if (!searchFilter) return;

  const searchFilterShow = document.getElementById("search-filter-show");
  const searchFilterHide = document.getElementById("search-filter-hide");

  searchFilterShow.addEventListener("click", showFilters);
  searchFilterHide.addEventListener("click", hideFilters);
};

export default init;
